import ApiService from './ApiService';
import Cookies from "js-cookie";
import {CURRENT_USER, TEMP_TOKEN} from "../constants/storage-key";
import store from '../store/configureStore';
import {setUserInfo} from "../actions/user";
import {User} from "../models/user.model";
import {UserHelper} from '../helpers/user-helper';

const useOTPForLogin = true;

class AuthService {
    async signIn(email, password, token) {
        const user = await ApiService.signIn(email, password, token);
        let requireOtp = false;
        if (useOTPForLogin && !user.remembered) {
            const expiryDate = new Date(new Date().getTime() + 10 * 60 * 1000) // 10 minutes from now
            Cookies.set(TEMP_TOKEN, user.apiToken, {expires: expiryDate});
            requireOtp = true;
        } else {
            // save user data to cookie
            Cookies.set(CURRENT_USER, user?.toJsonString());
            if (user?.type === User.TYPE_USER_PATIENT || user?.type === User.TYPE_USER_PHYSICIAN) {
                Cookies.set(CURRENT_USER, new User().initFromObject(UserHelper.getUserBaseData(user, true)).toJsonString());
            }
            // set current user to store
            store.dispatch(setUserInfo(user));
        }
        return {user, requireOtp};
    }

    signOut() {
        // remove user data to cookie
        Cookies.remove(CURRENT_USER);

        // remove current user to store
        store.dispatch(setUserInfo(null));
    }

    getCurrentUser() {
        let user = null;
        const u = Cookies.get(CURRENT_USER);

        if (u) {
            let userObj = JSON.parse(u);
            user = new User().deserialize(userObj);

            // set api token
            ApiService.setHeaderToken(user.apiToken);

            // set current user to store
            store.dispatch(setUserInfo(user));
        }

        return user;
    }
}


const authService = new AuthService();

export default authService;
