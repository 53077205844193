import Axios from 'axios'
import {User} from '../models/user.model'
import API from '../helpers/api'
import store from '../store/configureStore'
import {setToken, setUserInfo} from '../actions/user'
import {Quote} from '../models/quote.model'
import {Order} from '../models/order.model'
import {Permit} from '../models/permit.model'
import {ProductExcel} from '../models/product-excel.model'
import {Product} from '../models/product.model'
import fileDownload from 'js-file-download'
import Cookies from 'js-cookie'
import {CURRENT_USER, TEMP_TOKEN} from '../constants/storage-key'
import {UserHelper} from '../helpers/user-helper'
import {DoctorModel} from "../models/doctor.model";

class ApiService {
    baseUrl = `/api/v1`
    // baseUrl = `http://localhost:4040/api/v1`
    // baseUrl = process.env.REACT_APP_SERVER_URL
    // baseUrl = `https://development.lisfarmaapp.com/api/v1`;
    // baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/v1`
    // baseUrl = `https://development.lisfarmaapp.com/api/v1`
    // urlFileBase = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`

    // cancel token
    cancelSource = null

    //
    // rest apis
    //

    setHeaderToken(token) {
        store.dispatch(setToken(token))
    }

    async signIn(email, password, token) {
        const params = {
            email: email,
            password: password,
            // isAdmin: true,
            // isAdmin: false,
            token,
        }

        try {
            const {data} = await Axios.post(`${this.baseUrl}/login`, params, {})
            const user = new User().initFromObject(data.user)

            user.apiToken = data.token
            user.remembered = data.remembered
            this.setHeaderToken(data.token)

            return Promise.resolve(user)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async sendOtp(payload) {
        const token = Cookies.get(TEMP_TOKEN)

        try {
            const {data} = await Axios.post(`${this.baseUrl}/send-otp`, payload, {
                headers: {
                    token,
                },
            })
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async verifyOtp(payload) {
        const token = Cookies.get(TEMP_TOKEN)

        try {
            const {data} = await Axios.post(`${this.baseUrl}/verify-otp`, payload, {
                headers: {
                    token,
                },
            })
            const user = new User().initFromObject(data.user)
            user.apiToken = data.token
            this.setHeaderToken(data.token)
            // save user data to cookie
            Cookies.set(CURRENT_USER, user?.toJsonString())
            if (
                user?.type === User.TYPE_USER_PATIENT ||
                user?.type === User.TYPE_USER_PHYSICIAN
            ) {
                Cookies.set(
                    CURRENT_USER,
                    new User()
                        .initFromObject(UserHelper.getUserBaseData(user, true))
                        .toJsonString()
                )
            }
            // set current user to store
            store.dispatch(setUserInfo(user))
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async signup(payload) {
        try {
            const {data} = await Axios.post(`${this.baseUrl}/signup`, payload)
            const user = new User().initFromObject(data.user)
            user.apiToken = data.token
            this.setHeaderToken(data.token)
            return Promise.resolve(user)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    baseHeader() {
        if (!this.token) {
            return {}
        }

        return {
            token: this.token,
        }
    }

    //
    // users
    //
    async getUsers(filter, search, from = 0, count = 10, orderBys, payload = {}) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                order: JSON.stringify(orderBys),
                ...payload,
            }

            const {data} = await API.get('/users', {params})
            // ;

            return {
                count: data.count,
                users: data.users.map(u => new User().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async updateUserFields(values, userId = '') {
        try {
            const params = {
                userId,
                ...values,
            }

            await API.post('/users/update', params)
            return Promise.resolve(true)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async getUserById(id) {
        try {
            const {data} = await API.get(`/users/${id}`)

            const u = new User().initFromObject(data)
            return Promise.resolve(u)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getUserByEmail(email) {
        try {
            const {data} = await API.get(`/users/email/${email}`)

            const u = new User().initFromObject(data)
            return Promise.resolve(u)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async createUsersRaw(users) {
        try {
            const {data} = await API.post(`/users/createMultiple`, {users})

        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async verifyPersonalIdAndTaxId(personalId = null, taxId = null) {
        try {
            const {data} = await API.post(`/users/verify-personalId-and-tax-id`, {
                personalId,
                taxId,
            })
            return data
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async exportUsers(filter, search, orderBys) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                order: JSON.stringify(orderBys),
            }

            const {data} = await API.get('/users/export', {
                params,
                responseType: 'blob',
            })
            fileDownload(data, 'users.xlsx')
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    //
    // products
    //
    async getProductExcels(from = 0, count = 10) {
        try {
            const params = {
                from,
                count,
            }

            const {data} = await API.get(`/products/excels`, {params})


            return {
                count: data.count,
                files: data.files.map(f => new ProductExcel().initFromObject(f)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    urlExportProducts() {
        return `${this.baseUrl}/products/export`
    }

    async addProductsRaw(products) {
        try {
            for (const product of products) {
                product.addedFrom = 'web'
            }
            const {data} = await API.post(`/products/addMultiple`, {products})

        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async addProductExcel(fileName, fileNameDisplay, type) {
        try {
            const params = {fileName, fileNameDisplay, type}

            const {data} = await API.post(`/products/excel`, params)


            return Promise.resolve(new ProductExcel().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getProducts(filter, search, from = 0, count = 10, orderBys = {}) {
        try {
            // todo: change to post with json body
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                order: JSON.stringify(orderBys),
            }

            const {data} = await API.get(`/products`, {params})
            return {
                count: data.count,
                products: data.products.map(u => new Product().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getDoctors(filter, search, from = 0, count = 10, orderBys = {}) {
        try {
            // todo: change to post with json body
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                order: JSON.stringify(orderBys),
            }

            const {data} = await API.get(`/doctors`, {params})
            return {
                count: data.count,
                items: data.items.map(u => new DoctorModel().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getDoctorById(id) {
        try {

            const {data} = await API.get(`/doctors/${id}`)
            return new DoctorModel().initFromObject(data)
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async addDoctor(payload) {
        try {
            const {data} = await API.post(`/doctors`, payload)
            return data
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async updateDoctor(id, payload) {
        try {
            const {data} = await API.put(`/doctors/${id}`, payload)
            return data
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async getDistributors(filter, search, from = 0, count = 10, orderBys = {}) {
        try {
            // todo: change to post with json body
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                order: JSON.stringify(orderBys),
            }

            const {data} = await API.get(`/distributors`, {params})
            return {
                count: data.count,
                items: data.items.map(u => new DoctorModel().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getDistributorById(id) {
        try {

            const {data} = await API.get(`/distributors/${id}`)
            return new DoctorModel().initFromObject(data)
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async addDistributor(payload) {
        try {
            const {data} = await API.post(`/distributors`, payload)
            return data
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async updateDistributor(id, payload) {
        try {
            const {data} = await API.put(`/distributors/${id}`, payload)
            return data
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async getBillers(filter, search, from = 0, count = 10, orderBys = {}) {
        try {
            // todo: change to post with json body
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                order: JSON.stringify(orderBys),
            }

            const {data} = await API.get(`/billers`, {params})
            return {
                count: data.count,
                items: data.items.map(u => new DoctorModel().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getBillerById(id) {
        try {

            const {data} = await API.get(`/billers/${id}`)
            return new DoctorModel().initFromObject(data)
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async addBiller(payload) {
        try {
            const {data} = await API.post(`/billers`, payload)
            return data
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async updateBiller(id, payload) {
        try {
            const {data} = await API.put(`/billers/${id}`, payload)
            return data
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response.data)
        }
    }

    async updateProductFields(values, productId) {
        try {
            const params = {
                productId,
                ...values,
            }

            const {data} = await API.post('/products/update', params)


            return Promise.resolve(true)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async deleteProduct(productId) {
        try {
            const {data} = await API.delete(`/products/${productId}`)


            return Promise.resolve(data.result)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    //
    // quotes
    //
    async getQuotes(filter, from = 0, count = 10, orderBys = null) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                from,
                count,
            }

            if (orderBys) {
                params.order = orderBys
            }

            const {data} = await API.get(`/quotes`, {params})


            return {
                count: data.count,
                quotes: data.quotes.map(u => new Quote().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getQuoteById(id) {
        try {
            const {data} = await API.get(`/quotes/${id}`)


            const q = new Quote().initFromObject(data)
            return Promise.resolve(q)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async updateQuoteStatus(quote, status) {
        try {
            const {data} = await API.post(`/quotes/${quote.id}/status`, {status})


            return Promise.resolve(new Quote().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async makeQuote(quote) {
        try {
            const {data} = await API.post(`/quotes/${quote.id}/quote`, {
                products: quote.products.map(p => {
                    return {id: p.id, price: p.price}
                }),
                shippingCost: Math.max(quote.shippingCost, 0),
                tax: Math.max(quote.tax, 0),
            })


            return Promise.resolve(new Quote().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // orders
    //
    async createOrder(permit) {
        try {
            const {data} = await API.post('/orders', {permit}, {})


            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async getOrders(
        filter,
        from = 0,
        count = 10,
        orderBys = null,
        populates = []
    ) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                from,
                count,
                withFields: populates.join(','),
            }

            if (orderBys) {
                params.order = orderBys
            }

            const {data} = await API.get(`/orders`, {params})


            return {
                count: data.count,
                orders: data.orders.map(u => new Order().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getOrderById(id) {
        try {
            const {data} = await API.get(`/orders/${id}`)


            const o = new Order().initFromObject(data)
            return Promise.resolve(o)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async updateOrderStatus(order, status) {
        try {
            const {data} = await API.post(`/orders/${order._id}/status`, {
                status,
            })


            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async updateOrderFields(permitId, values) {
        try {
            const {data} = await API.post(`/orders/update/${permitId}`, values)


            return Promise.resolve(true)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // import permits
    //
    async getPermits(
        filter,
        search,
        from = 0,
        count = 10,
        orderBys = null,
        populates = []
    ) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                populate: populates.join(','),
                order: JSON.stringify(orderBys),
            }

            if (orderBys) {
                params.order = orderBys
            }

            const {data} = await API.get(`/permits`, {params})
            return {
                count: data.count,
                permits: data.permits.map(u => new Permit().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async exportPermits() {
        try {
            const {data} = await API.get('/permits/export', {
                responseType: 'blob',
            })
            fileDownload(data, 'repositories.xlsx')
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    /**
     * deprecated
     *
     * @param id
     * @param populates
     * @return {Promise<Permit>}
     */
    async getPermitById(id, populates = []) {
        try {
            const {data} = await API.get(`/permits/${id}`, {
                params: {populate: populates},
            })


            const p = new Permit().initFromObject(data)
            return Promise.resolve(p)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async permitById(id, populates = []) {
        try {
            const {data} = await API.get(`/permits/${id}`, {
                params: {populate: populates},
            })


            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async updatePermitStatus(permit, payload) {
        try {
            const {data} = await API.post(`/permits/${permit.id}/status`, payload)


            return Promise.resolve(new Permit().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async updatePermitComment(permit, comment) {
        try {
            const {data} = await API.post(`/permits/${permit.id}/comment`, {
                comment,
            })


            return Promise.resolve(new Permit().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async updatePermitFields(permitId, values) {
        try {
            const {data} = await API.post(`/permits/update/${permitId}`, values)


            return Promise.resolve(true)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async approvePermitDocument(permit, document) {
        try {
            const {data} = await API.post(`/permits/approveDocument/${permit.id}`, {
                document,
            })


            return Promise.resolve(new Permit().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async rejectPermitDocument(permit, document, reason) {
        try {
            const {data} = await API.post(`/permits/rejectDocument/${permit.id}`, {
                document,
                reason,
            })


            return Promise.resolve(new Permit().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async createPermit(product, patientId = '', products, payload = {}) {
        try {
            const {data} = await API.post('/permits', {
                product: product._id,
                quantity: product.quantity,
                patient: patientId ? patientId : undefined,
                products,
                ...payload,
            })
            const permit = new Permit().initFromObject(data)

            return Promise.resolve(permit)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // s3
    //
    async getUploadUrl(params) {
        try {
            const {data} = await API.post('/s3/upload', params)


            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async uploadFile(file, path, fileName = null) {
        let res = await this.getUploadUrl({
            path: path,
            fileName: fileName ? fileName : file.name,
            fileType: file.type,
        })
        let uploadUrl = res.uploadUrl

        const options = {
            headers: {
                'Content-Type': file.type,
            },
        }

        await Axios.put(uploadUrl, file, options)

        return Promise.resolve(res.fileName)
    }

    async getDownloadUrl(path) {
        try {
            const {data} = await API.post('/s3/download', {filePath: path})
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // emails
    //
    async getEmailsByPermit(permitId) {
        try {
            const {data} = await API.get(`/emails/permit/${permitId}`)
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async sendLandingEmail(permitId, type) {
        try {
            const {data} = await API.post(`/permits/sendLandingEmail/${permitId}`, {
                type,
            })

            return Promise.resolve(new Permit().initFromObject(data))
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // repositories
    //
    async createRepository(repo) {
        try {
            const {data} = await API.post(`/repositories`, repo)

            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async updateRepository(repo) {
        try {
            const {data} = await API.post(`/repositories/${repo._id}`, repo)

            return Promise.resolve(true)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // messages
    //
    async getMessages(
        filter,
        from = 0,
        count = 10,
        orderBys = null,
        populates = []
    ) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                from,
                count,
            }

            if (orderBys) {
                params.order = orderBys
            }

            const {data} = await API.get(`/messages`, {params})

            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getMessageById(id) {
        try {
            const {data} = await API.get(`/messages/${id}`)

            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    //
    // reports
    //
    async getReports(
        filter,
        search,
        from = 0,
        count = 10,
        orderBys = null,
        populates = []
    ) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                from,
                count,
            }

            if (orderBys) {
                params.order = orderBys
            }

            const {data} = await API.get(`/reports`, {params})

            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getReportById(id) {
        try {
            const {data} = await API.get(`/reports/${id}`)

            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    //
    // profile
    //
    async updatePassword(oldPwd, newPwd) {
        try {
            const {data} = await API.post('/users/change-password', {
                oldPwd,
                newPwd,
            })

            return Promise.resolve(data)
        } catch (e) {
            console.log(e)
            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    //
    // Create-Medical-History
    //
    async getMedicalHistory(id) {
        try {
            const response = await API.get(`/medical-history/${id}`)
            return Promise.resolve(response)
        } catch (e) {
            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async createMedicalHistory(data) {
        try {
            const response = await API.post(`/medical-history`, data)
            return Promise.resolve(response)
        } catch (e) {
            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async updateMedicalHistory(id, data) {
        try {
            const response = await API.put(`/medical-history/${id}`, data)
            return Promise.resolve(response)
        } catch (e) {
            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async searchMedicalHistory(search, skip = 0, limit = 10, orderBy) {
        try {
            const params = {
                search: JSON.stringify(search),
                limit,
                skip,
                orderBy,
            }
            const {data} = await API.get('/medical-history', {
                params,
            })
            return {
                count: data.count,
                items: data.items,
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async searchMedicalHistoryPatients(search, skip = 0, limit = 10, orderBy) {
        try {
            const params = {
                search: JSON.stringify(search),
                limit,
                skip,
                orderBy,
            }
            const {data} = await API.get('/medical-history/patients', {
                params,
            })
            return {
                count: data.count,
                items: data.items,
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async removePatient(medicalHistoryId, typeUserId) {
        try {
            const {data} = await API.put(
                `/medical-history/${medicalHistoryId}/patients`,
                {
                    typeUserId,
                }
            )
            return data
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async importRepositories(form) {
        try {
            const {data} = await API.post('/permits/import', form)
            return data
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async createNewPermit(form) {
        try {
            const {data} = await API.post('/permits/new', form)
            return data
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getConsultedDoctorsOfPatient(
        filter,
        search,
        from = 0,
        count = 10,
        orderBys,
        payload = {}
    ) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                order: JSON.stringify(orderBys),
                ...payload,
            }
            const {data} = await API.get('/users/consulted-doctors', {
                params,
            })
            // const {data} = await API.get('/users/consulted-doctors');
            // console.log('data >>>',data);
            return {
                count: data.count,
                users: data.users.map(u => new User().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getPatientsDocuments(search, skip = 0, limit = 10, orderBy) {
        try {
            const params = {
                search: JSON.stringify(search),
                limit,
                skip,
                orderBy,
            }
            const {data} = await API.get('/documents', {
                params,
            })
            return {
                count: data.count,
                items: data.items,
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getUserPermits(
        filter,
        search,
        from = 0,
        count = 10,
        orderBys = null,
        populates = []
    ) {
        try {
            const params = {
                filter: JSON.stringify(filter),
                search: JSON.stringify(search),
                from,
                count,
                populate: populates.join(','),
            }

            if (orderBys) {
                params.order = orderBys
            }

            const {data} = await API.get('/permits', {params})


            return {
                count: data.count,
                permits: data.permits.map(p => new Permit().initFromObject(p)),
                // permits: data.permits,
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getPatientsHistory(search, skip = 0, limit = 10, orderBy) {
        try {
            const params = {
                search: JSON.stringify(search),
                limit,
                skip,
                orderBy,
            }
            const {data} = await API.get('/medical-history', {
                params,
            })
            return {
                data,
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async getPatientMedicalHistory(payload = {}) {
        try {
            const {data} = await API.get('/medical-history', {
                params: {
                    ...payload,
                },
            })
            return data
        } catch (e) {
            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async getMyPatients(
        filter,
        search,
        from = 0,
        count = 10,
        orderBys,
        payload = {}
    ) {
        try {
            const {data} = await API.get('/users/me/patients', {
                params: {
                    filter: JSON.stringify(filter),
                    search: JSON.stringify(search),
                    from,
                    count,
                    order: JSON.stringify(orderBys),
                    ...payload,
                },
            })


            // return Promise.resolve(data?.map((u) => new User().initFromObject(u)));
            return {
                count: data.count,
                users: data.users.map(u => new User().initFromObject(u)),
            }
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async createUser(values) {
        try {
            const {data} = await API.post('/createUser', values)


            const user = new User().initFromObject(data.user)

            return Promise.resolve(user)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async updateDocument(documentData) {
        try {
            const {data} = await API.post('/documents', documentData)
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async getDocument() {
        try {
            const {data} = await API.get('/documents')


            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response.data)
        }
    }

    async s3CopyFile(srcPath, destPath) {
        try {
            const {data} = await API.post('/s3/copy', {
                sourcePath: srcPath,
                filePath: destPath,
            })


            return Promise.resolve(data)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async sendPermitRequestNotification(email, phone, products) {
        try {
            const res = await API.post('/permits/notify', {
                email,
                phone,
                products,
            })

            return Promise.resolve(res)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }

    async imageToText(file) {
        const formData = new FormData()
        formData.append('file', file)

        try {
            const res = await API.post('/ocr', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            })

            return Promise.resolve(res)
        } catch (e) {
            console.log(e)

            return Promise.reject(e.response ? e.response.data : e)
        }
    }
}

const apiService = new ApiService()

export default apiService
