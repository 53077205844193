import {BaseModel} from './base.model';

export class ProductExcel extends BaseModel {
  //
  // properties
  //
  fileName = '';
  fileNameDisplay = '';
  type = '';
  status = '';
}
