import {
  SET_ITEM_SELECTED, SET_PERMIT_SELECTED,
  SET_SHOW_LOADING,
  SET_TABLE_DATA, SET_USER_SELECTED
} from "../constants/action-types";

// ui reducer
export const setShowLoading = (showLoading) => {
  return {
    type: SET_SHOW_LOADING,
    payload: showLoading,
  };
};

// data reducer
export const setTableData = (data) => {
  return {
    type: SET_TABLE_DATA,
    payload: data,
  };
};

export const setItemSelected = (item) => {
  return {
    type: SET_ITEM_SELECTED,
    payload: item,
  };
};

export const setUserSelected = (user) => {
  return {
    type: SET_USER_SELECTED,
    payload: user,
  };
};

export const setPermitSelected = (permit) => {
  return {
    type: SET_PERMIT_SELECTED,
    payload: permit,
  };
};
