import {BaseQuote} from "./base-quote.model";
import {Quote} from "./quote.model";
import _ from 'lodash';
import moment from "moment";

export class Order extends BaseQuote {
  // status
  static STATUS_PENDING = 'pending';
  static STATUS_SENT = 'sent';
  static STATUS_READ = 'read';
  static STATUS_DELIVERED = 'delivered';
  static STATUS_CANCELED = 'canceled';
  static STATUS_UNREAD = 'unread';

  static PAYMENT_PENDING = 'pending';
  static PAYMENT_PAID = 'paid';

  static STORAGE_PRESC_PATH = 'prescriptions';
  static STORAGE_PATH = 'orders';

  static KEY_IMPORT_PERMIT = 'fileImportPermit';
  static KEY_PURCHASE_ORDER = 'filePurchaseOrder';
  static KEY_PROOF_DELIVERY = 'fileProofDelivery';
  static KEY_PAYMENT_PROOF = 'filePaymentProof';
  static KEY_PHOTO_PRODUCT = 'filePhotoProduct';
  static KEY_PRESCRIPTION = 'filePrescription';

  paymentStatus = Order.PAYMENT_PENDING;
  filePaymentProof = '';

  filePrescription = '';
  filePhotoProduct = '';

  quoteId = '';
  deliveredAt = null;

  // logical
  quote = null;

  initFromObject(data) {
    super.initFromObject(data);

    // customer
    if (_.isObject(this.quote)) {
      this.quote = new Quote().initFromObject(this.quote);
    } else {
      this.quoteId = this.quote;
    }

    if (data.deliveredAt) {
      this.deliveredAt = moment(data.deliveredAt);
    }

    return this;
  }

  priceTotal() {
    let price = 0;
    price +=
      Math.max(this.priceSubTotal(), 0) +
      Math.max(this.quote?.shippingCost, 0) +
      Math.max(this.quote?.tax, 0);

    return price > 0 ? price : -1;
  }
}
