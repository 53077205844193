export const ROUTES = {
  LOGIN: '/login',
  OTP_VERIFICATION: '/otp/verification',
  REGISTER: '/register',

  HOME: '/home',
  JOIN_MEDICAL_HISTORY: '/join-medical-history',

  CUSTOMERS: '/customers',
  CUSTOMERS_PATIENTS: '/customers/patients',
  CUSTOMERS_PHYSICIANS: '/customers/physicians',
  CUSTOMERS_INSTITUTIONS: '/customers/institutions',

  CUSTOMER_CREATE: '/customers/create',
  CUSTOMER_EDIT: '/customers/edit',
  CUSTOMER_EDIT_PHYSICIAN: '/customers/edit/physician',
  CUSTOMER_EDIT_INSTITUTION: '/customers/edit/institution',

  CUSTOMER_HISTORY: '/customers/history',
  CUSTOMER_HISTORY_QUOTATION: '/customers/history/quotation',
  CUSTOMER_HISTORY_ORDER: '/customers/history/order',
  CUSTOMER_HISTORY_IMPORT: '/customers/history/import',
  CUSTOMER_HISTORY_IMPORT_LANDING: '/customers/history/import-landing',
  CUSTOMER_HISTORY_MEDICAL_HISTORY: '/customers/history/medical-history',
  CUSTOMER_HISTORY_MEDICAL_HISTORY_PATIENTS:
    '/customers/history/medical-history-patients',
  CUSTOMER_HISTORY_PHYSICIANS: '/customers/history/medical-history-physicians',

  CUSTOMER_QUOTE_DETAIL: '/customers/quote/',
  CUSTOMER_QUOTE_DETAIL_PHYSICIAN: '/customers/quote/physician',
  CUSTOMER_QUOTE_DETAIL_INSTITUTION: '/customers/quote/institution',

  CUSTOMER_ORDER_DETAIL: '/customers/order/',
  CUSTOMER_ORDER_DETAIL_PHYSICIAN: '/customers/order/physician',
  CUSTOMER_ORDER_DETAIL_INSTITUTION: '/customers/order/institution',

  CUSTOMER_IMPORT_DETAIL: '/customers/import',

  USERS: '/users',
  USER_EDIT: '/users/edit',
  USER_HISTORY: '/users/history',

  PRODUCTS: '/products',
  PRODUCTS_EXCEL_UPLOAD: '/products/excel-upload',
  PRODUCTS_EXCEL_DOWNLOAD: '/products/excel-download',

  IMPORTS: '/imports',
  IMPORT_EDIT: '/imports/edit',
  LANDING_PERMITS: '/landing-permits',
  LANDING_PERMIT_EDIT: '/landing-permits/edit',

  QUOTATIONS: '/quotations',
  QUOTATION_EDIT: '/quotations/edit',

  ORDERS: '/orders',
  ORDER_EDIT: '/orders/edit',

  QUERY_LANDING: 'landing',

  REPOSITORY: '/repository',
  REPOSITORY_LIST: '/repository/list',
  REPOSITORY_DETAIL: '/repository/detail',
  REPOSITORY_CREATE: '/repository/create',
  MEDICAL_HISTORY: '/medical-history',
  MEDICAL_SEARCH_HISTORY: '/medical-history/search-medical-history',
  MEDICAL_CREATE_HISTORY: '/medical-history/create-medical-history',
  MEDICAL_EDIT_HISTORY: '/medical-history/edit-medical-history',

  REPORTS: '/reports',
  REPORTS_DETAILS: '/reports/:id',

  INBOX: '/inbox',
  INBOX_ID: '/inbox/:id',
  INBOX_DETAIL: '/inbox/detail',

  PROFILE: '/profile',
  PERSONAL_INFORMATION: '/personal-information',
  DOCUMENT: '/document',
  CHAT: '/chat',
  HOW_WORK: '/howWork',
  LEGAL: '/legal',
  CONFIGURATION: '/configuration',
  USER_MEDICAL_HISTORY: '/user-medical-history',
  // for doctors
  USER_MEDICAL_HISTORY_LIST: '/user-medical-history/list',
  CREATE_USER_MEDICAL_HISTORY: '/user-medical-history/create',
  USER_IMPORTS: '/user/imports',
  REQUEST_IMPORT_PERMIT: '/user/imports/request-import-permit',
  EDIT_USER_IMPORT: '/user/imports/edit',
  PERMISSIONS: '/permissions',
  CONSULTED_DOCTORS: '/consulted-doctors',
  MY_PATIENTS: '/my-patients',
  MY_PATIENTS_LIST: '/my-patients/list',
  REGISTER_NEW_PATIENTS: '/my-patients/register',

  // UPLOAD PDF & RETURN RECOGNISE TEXT
  OCR: '/ocr',
}

export default ROUTES

export const QueryParams = {
  PAGE: 'page',
  PERMIT: 'permit',
}
