import {SET_TOKEN, SET_USER} from '../constants/action-types';

export const setUserInfo = (userInfo) => {
  return {
    type: SET_USER,
    payload: userInfo,
  };
};

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

