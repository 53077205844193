import {BaseModel} from "./base.model";
import {User} from "./user.model";
import {Product} from "./product.model";
import {Status} from "./status.model";
import _ from 'lodash';

export class BaseQuote extends BaseModel {
  //
  // properties
  //
  customerType = '';
  products = [];
  customerId = '';
  userId = '';
  currentStatus = '';
  statuses = [];

  // logical
  customer = null;
  user = null;

  initFromObject(data) {
    super.initFromObject(data);

    // customer
    if (_.isObject(this.customer)) {
      this.customer = new User().initFromObject(this.customer);
    } else {
      this.customerId = this.customer;
    }

    // user
    if (_.isObject(this.user)) {
      this.user = new User().initFromObject(this.user);
    } else {
      this.userId = this.user;
    }

    this.products = this.products.map((p) => new Product().initFromObject(p));
    this.statuses = this.statuses.map((s) => new Status().initFromObject(s));

    // sort statuses in descending order
    this.statuses = _.orderBy(this.statuses, ['createdAt'], ['desc']);

    return this;
  }

  priceSubTotal() {
    let price = 0;
    for (const p of this.products) {
      price += Math.max(p.price * p.quantity, 0);
    }

    return price > 0 ? price : -1;
  }

  // temporary
  product() {
    if (_.isEmpty(this.products)) {
      return null;
    }

    return this.products[0];
  }
}
