import {SET_TOKEN, SET_USER} from '../constants/action-types';

const initialState = {
  user: null,

  // tokens
  token: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
};
