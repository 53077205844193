import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import translationEng from './locales/en/translation.json';
import translationEsp from './locales/es/translation.json';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,
        resources: {
            en: {
                translation: translationEng,
            },
            es: {
                translation: translationEsp,
            },
        },
        lng: ['en', 'es'].includes(localStorage.getItem('i18nextLng')) ? localStorage.getItem('i18nextLng') : 'es',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
