import moment from 'moment';

export class BaseModel {
  id = '';
  idSeq = 0;
  createdAt = moment();

  initFromObject(data) {
    Object.assign(this, data);

    if (data._id) {
      this.id = data._id;
    } else if (data.id) {
      this.id = data.id;
    }

    if (data.idSeq) {
      this.idSeq = data.idSeq;
    }

    if (data.createdAt) {
      this.createdAt = moment(data.createdAt);
    }

    return this;
  }

  setProperty(data, propName) {
    if (propName in data) {
      this[propName] = data[propName];
    }
  }

  toJsonString() {
    return JSON.stringify(this);
  }

  deserialize(input) {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.parse(this.toJsonString());
  }
}
