import {SET_ACTIVE_SIDEMENU} from "../constants/action-types";

const initialState = {
  activeSideMenu: 'null',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_SIDEMENU:
      return {
        ...state,
        activeSideMenu: action.payload,
      };

    default:
      return state;
  }
};
