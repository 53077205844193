import {BaseQuote} from "./base-quote.model";
import moment from "moment";

export class Quote extends BaseQuote {

  // status
  static STATUS_SENT = 'sent';
  static STATUS_PENDING = 'pending';
  static STATUS_READ = 'read';
  static STATUS_REJECTED = 'rejected';
  static STATUS_UNREAD = 'unread';

  //
  // properties
  //
  currentStatus = Quote.STATUS_SENT;

  shippingCost = -1;
  tax = -1;
  quotedAt = null;

  quoteFile = '';

  rejectReason = '';
  rejectReasonOther = '';

  initFromObject(data) {
    super.initFromObject(data);

    if (data.quotedAt) {
      this.quotedAt = moment(data.quotedAt);
    }

    return this;
  }

  priceTotal() {
    let price = 0;
    price += Math.max(this.priceSubTotal(), 0) + Math.max(this.shippingCost, 0) + Math.max(this.tax, 0);

    return price > 0 ? price : -1;
  }
}
