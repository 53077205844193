import {combineReducers} from 'redux';

import {reducer as MenuReducer} from './MenuReducer';
import {reducer as UserReducer} from './UserReducer';
import {reducer as QuoteReducer} from './QuoteReducer';
import {reducer as UiReducer} from './UiReducer';

export default combineReducers({
  MenuReducer,
  UserReducer,
  QuoteReducer,
  UiReducer,
});
