import {BaseModel} from './base.model';
import {config} from "../helpers/config";
import _ from 'lodash';

export class Product extends BaseModel {
  static STORAGE_PATH = 'products';
  static STORAGE_PATH_IMAGE = 'products/images';

  static TEMP_CODE_PREFIX = 'tmp-';

  //
  // properties
  //
  ean = '';
  code = '';
  image = '';
  description = '';
  api = '';
  manufacturer = '';
  countryOrigin = '';
  countryMarketing = '';
  countryManufacturer = '';

  enabled = true;

  //
  // quote
  //
  quantity = 1;
  price = -1;
  deliveryCountry = config.defaultCountry;
  deliveryAddress = '';
  salesPrice = undefined;

  //
  // logical
  //
  filePhoto = null;

  initFromObject(data) {
    super.initFromObject(data);

    // quote product has pure product info in it
    if (_.isObject(data.product)) {
      Object.assign(this, data.product);
    }

    return this;
  }

  codeDisplay() {
    if (this.code.startsWith(Product.TEMP_CODE_PREFIX)) {
      return '';
    }

    return this.code;
  }
}
