import {
  SET_ITEM_SELECTED, SET_PERMIT_SELECTED,
  SET_SHOW_LOADING,
  SET_USER_SELECTED
} from "../constants/action-types";

const initialState = {
  showLoading: false,

  // page data
  userSelected: null,
  permitSelected: null,
  itemSelected: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };

    //
    // page data
    //
    case SET_USER_SELECTED:
      return {
        ...state,
        userSelected: action.payload,
      };

    case SET_PERMIT_SELECTED:
      return {
        ...state,
        permitSelected: action.payload,
      };

    case SET_ITEM_SELECTED:
      return {
        ...state,
        itemSelected: action.payload,
      };

    default:
      return state;
  }
};
